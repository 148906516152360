export interface IIntellisense {
  obj: any;
  docs?: any;
}

const validAndTransformKey = (
  key: string | undefined,
  useDotInStart = false
) => {
  if (!key) return key;
  const isValid = /^[a-zA-Z_$][a-zA-Z0-9_$]*$/.test(key);

  return isValid ? `${useDotInStart ? "." : ""}${key}` : `["${key}"]`;
};

const declareProperty = (declareObj: string, obj: any, key: string) => {
  return (declareObj += `${validAndTransformKey(key)}: ${typeof obj}`);
};

const declareArray = (declareObj: string, obj: any, key: string) => {
  const keyToUse = validAndTransformKey(key);
  if (!obj.length) return `${keyToUse}: any[]`;
  if (typeof obj[0] !== "object") return `${keyToUse}: ${typeof obj[0]}[]`;

  return `${keyToUse}: Array<${subDeclare(declareObj, obj[0], key, false)}>`;
};

const declareObject = (
  declareObj: string,
  obj: any,
  key: string,
  useKey: boolean = true
) => {
  let objType = ``;

  Object.keys(obj).forEach((subKey) => {
    objType += `${subDeclare(declareObj, obj[subKey], subKey)};`;
  });

  const result = objType ? `{ ${objType} }` : "any";

  if (useKey) return `${validAndTransformKey(key)}: ${result}`;

  return result;
};

const subDeclare = (
  declareObj: string,
  obj: any,
  key: string,
  useKey: boolean = true
): string => {
  if (typeof obj === "object") {
    if (Array.isArray(obj)) return declareArray(declareObj, obj, key);

    return declareObject(declareObj, obj, key, useKey);
  }

  return declareProperty(declareObj, obj, key);
};

export const buildDeclarations = (data: IIntellisense) => {
  const { obj, docs } = data;
  let declarations = ``;

  Object.keys(obj).forEach((key) => {
    if (docs[key] && typeof docs[key] === "string")
      declarations += `${docs[key]}\n`;
    
    declarations += `declare const ${subDeclare("", obj[key], key, true)};\n`;
  });

  return declarations;
};
